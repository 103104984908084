






import { Component, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalShowListUnit extends Mixins(ModalBaseMethod) {
  private title = ''

  private testRanges: string[] = []

  public showSchoolTest(dataRange: string[], title: string) {
    this.title = title
    this.testRanges = [...dataRange]
    this.show()
  }
}
