
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import ModalShowListUnit from '@/components/organisms/v3/mode/ModalShowListUnit.vue'
import { Grade, Page, Subject, TypeScope, UnitResponse } from '@/types/teacher/mode'

@Component({
  components: {
    ModalShowListUnit,
  },
})
export default class PopupScopeBase extends Vue {
  @Ref()
  readonly modalShowListUnit!: ModalShowListUnit

  @Prop()
  subjects!: Subject[]

  @Prop()
  grades!: Grade[]

  @Prop()
  onClose!: () => void

  @Prop()
  onSubmit!: (target: { [key: number]: any }, scope?: TypeScope) => void

  @Prop()
  target!: { [key: number]: any }

  @Prop({ default: {} })
  private scope!: any

  protected branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  protected subject!: Subject
  protected showModalPage = false
  protected showModalUnit = false
  protected scopeClone = cloneDeep(this.scope)
  protected scopeOrigin = cloneDeep(this.scope)
  protected targetClone = cloneDeep(this.target)
  protected targetOrigin = cloneDeep(this.target)
  protected subjectsClone: Subject[] = []
  protected isEditMode = !!this.$route.params?.modeId

  protected async showListUnits(subjectId: number): Promise<void> {
    let dataRange: string[] = []
    const title: string = this.subjects.find((subject) => subject.subjectId === subjectId)?.name || ''
    const currentSubjectTarget = this.targetClone[subjectId]
    if (currentSubjectTarget[0]?.curriculum_mode_code) {
      const pages = currentSubjectTarget.map(({ curriculum_mode_code, text, ...pageRest }: any) => pageRest)
      const result = await Vue.prototype.$http.httpWithToken.post(
        '/v3/publisher_curriculum_s_units/curriculum_s_units_by_page',
        {
          pages,
        }
      )
      dataRange = result.data.units.map((item: UnitResponse) => item.gradeName + ' ' + item.sUnitName)
      this.modalShowListUnit.showSchoolTest(dataRange, title)
      return
    }
    dataRange = this.targetClone[subjectId].map((item: any) => {
      if (item.text) {
        const indexOfPageText = item.text.indexOf('(')
        if (indexOfPageText === -1) {
          return item.text
        }
        return item.text.slice(0, indexOfPageText)
      }
      if (item.units) {
        return item.units.map((unit: any) => {
          const indexOfPageText = unit.text.indexOf('(')
          if (indexOfPageText === -1) {
            return unit.text
          }
          return unit.text.slice(0, indexOfPageText)
        })
      }
      return ''
    })
    this.modalShowListUnit.showSchoolTest(dataRange.flat(), title)
  }

  protected checkActive(subjectId: number) {
    return !!this.targetClone[subjectId]?.length
  }

  protected handleSelectPage(rows: Page[]) {
    this.targetClone && (this.targetClone[this.subject.id] = rows)
    this.targetClone = cloneDeep(this.targetClone)
    this.scopeOrigin = cloneDeep(this.scopeClone)
    this.handleCancelSelectPage()
  }

  protected handleCancelSelectPage() {
    this.scopeClone = cloneDeep(this.scopeOrigin)
    this.showModalPage = false
  }

  protected onReset(subjectId: number) {
    this.subjectsClone.forEach((subject, index) => {
      if (subject.subjectId == subjectId) {
        this.subjects[index].checked = false
        delete this.targetClone[subjectId]
      }
    })

    this.targetClone = cloneDeep(this.targetClone)
  }

  protected handleUpdateSelectUnit(data: any) {
    this.scopeOrigin = cloneDeep(this.scopeClone)
    this.targetClone = cloneDeep(data)
  }

  protected handleCloseModalConfirm() {
    this.targetClone = cloneDeep(this.targetOrigin)
    this.scopeClone = cloneDeep(this.scopeOrigin)
    this.onClose()
  }

  get targetUnits() {
    const _targetClone = cloneDeep(this.targetClone)
    if (
      this.scopeClone[this.subject.id] !== this.scopeOrigin[this.subject.id] &&
      this.scopeClone[this.subject.id] === TypeScope.Unit
    ) {
      _targetClone[this.subject.id] = []
    }
    return _targetClone
  }

  get targetPages() {
    return this.scopeClone[this.subject.id] !== this.scopeOrigin[this.subject.id] &&
      this.scopeClone[this.subject.id] === TypeScope.Page
      ? [{ gradeId: '', startPage: '', endPage: '' }]
      : this.targetClone[this.subject.id]
  }

  openModalChild(index: number, type = TypeScope.Unit) {
    this.subjectsClone[index].checked = true
    this.subject = cloneDeep(this.subjectsClone[index])
    this.scopeClone[this.subject.id] = type
    type === TypeScope.Unit ? (this.showModalUnit = true) : (this.showModalPage = true)
  }

  handleClose() {
    this.showModalUnit = false
    this.scopeClone = cloneDeep(this.scopeOrigin)
  }

  handleSubmit() {
    this.targetOrigin = cloneDeep(this.targetClone)
    this.scopeOrigin = cloneDeep(this.scopeClone)
    this.onSubmit(this.targetClone, this.scopeClone)
    this.onClose()
  }

  protected async callApiCheckPage(pagesParam: Page[]) {
    const res = await Vue.prototype.$http.httpWithToken.post('/v3/publisher_curriculum_s_units/page_exist', {
      pages: pagesParam,
    })
    if (res.data.exist) {
      this.handleSelectPage(pagesParam)
      this.handleCancelSelectPage()
    } else {
      alert('教科書設定が標準になっています。教科書設定をおこなってください。')
    }
  }

  private created() {
    this.subjectsClone = this.subjects.map((subject) => ({
      ...subject,
      checked: this.checkActive(subject.id as number),
    }))
  }
}
