


























import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import UnitSelector from '@/components/organisms/v3/mode/UnitSelector.vue'
import { BookOptions, Grade, Subject, UnitResponse } from '@/types/teacher/mode'

@Component({
  components: {
    PopupBase,
    ButtonBase,
    UnitSelector,
    CheckboxSquare,
  },
})
export default class ModalSelectUnit extends Vue {
  @Prop()
  onClose!: () => void

  @Prop()
  onSubmit!: (targetUnits: any) => void

  @Prop()
  subjects!: Subject[]

  @Prop()
  grades!: Grade[]

  @Prop()
  private targetUnits!: any

  @Prop()
  private schoolId?: number

  @Prop()
  private publisherId?: number

  @Prop()
  private isScopeBook?: boolean

  @Prop()
  private bookOptions?: BookOptions

  @Prop({ default: false })
  private isScopeGDLS?: boolean

  @Prop({ default: null })
  private getUnits!: (gradeId: string | number, subject: Subject, publisherId: string) => UnitResponse[]

  private crlData: any[] = []

  private modalWidth = 1250

  private handleSubmit() {
    this.subjects.forEach((subject, index) => {
      this.$set(subject, 'checked', this.crlData[index].checked)
      if (subject.checked) {
        this.schoolId &&
          (this.crlData[index].targetInit = this.crlData[index].targetInit.map((item: any) => ({
            ...item,
            schoolId: this.schoolId,
          })))
        !this.schoolId &&
          (this.crlData[index].targetInit = this.crlData[index].targetInit.map((item: any) => ({
            ...item,
          })))
        this.targetUnits[subject.id] = [...this.crlData[index].targetInit]
      }
    })
    this.onSubmit(this.targetUnits)
    this.onClose()
  }

  private get colortype() {
    return this.crlData.some((item) => !!item.targetInit.length) ? 'blue' : 'pointer-events-none'
  }

  private scrollToSelectUnit(id: number) {
    setTimeout(() => {
      const element = document.getElementById('section' + id)
      element?.scrollIntoView()
    }, 50)
  }

  private mounted() {
    this.crlData = this.subjects.reduce((result: any[], current) => {
      const item = { ...current, targetInit: this.targetUnits[current.id] || [] }
      result.push(item)
      return result
    }, [])
    this.modalWidth = window.innerWidth
  }
}
