


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectMultiple from '@/components/atoms/SelectMultiple.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import { BookOptions, Grade, Subject, Unit, UnitResponse } from '@/types/teacher/mode'
import { mapPublisherIdWithGradeId } from '@/utils/teacher/mode'

@Component({
  components: {
    TabBase,
    SelectMultiple,
    ButtonBase,
    SelectBase,
  },
})
export default class UnitSelector extends Vue {
  @Prop({ default: '' })
  private subject!: Subject & { targetInit: any[] }
  @Prop()
  private grades!: Grade[]

  @Prop({ default: null })
  private getUnits!: (gradeId: string | number, subject: Subject, publisherId?: string) => UnitResponse[]

  @Prop({ default: [] })
  private targetInit!: Unit[]

  @Prop({ default: false })
  private isScopeBook!: boolean

  @Prop()
  private bookOptions!: BookOptions

  private studyUnits: Unit[] = []
  private targetUnits: Unit[] = []
  private publisherIdsOfGradeIds = mapPublisherIdWithGradeId(this.targetInit as any)
  private publisherIds = !this.isScopeBook
    ? null
    : this.grades.reduce((result: any, grade: Grade) => {
        result[grade.id] = this.publisherIdsOfGradeIds[grade.id] || ''
        return result
      }, {})

  private gradeId: string | number = ''
  private selectedUnits: string[] = []
  private selectedTargetUnits: string[] = []

  @Watch('gradeId')
  @Watch('publisherIds', { deep: true })
  async watchGradeIdChanged() {
    if (this.isScopeBook && this.publisherIds[this.gradeId] === '') {
      this.removeUnitByGrade(this.gradeId)
      this.studyUnits = []
      this.fillTargetUnit()
      return
    }
    if (this.isScopeBook) {
      this.removeUnitByGrade(this.gradeId)
    }
    const result = await this.getUnits(
      this.gradeId,
      this.subject,
      this.publisherIds ? this.publisherIds[this.gradeId] : undefined
    )
    this.studyUnits = result.map((unit) => {
      const textUnit = `${unit.gradeName || this.getGradeName()} ${unit.sUnitName}${
        unit.gradeName ? '(' + unit.name + ':p' + unit.startPage + '-p' + unit.endPage + ')' : ''
      }`
      const unitItem: { value: string; text: string; gradeId: string | number; publisherId?: string } = {
        value: unit.id.toString(),
        text: textUnit,
        gradeId: this.gradeId,
      }
      if (this.isScopeBook) {
        unitItem.publisherId = this.publisherIds[this.gradeId]
        return unitItem
      }
      return unitItem
    }) as Unit[]
    this.fillTargetUnit()
  }

  @Watch('targetUnits', { deep: true })
  async watchTargetUnits() {
    this.subject.targetInit = [...this.targetUnits]
  }

  private fillTargetUnit() {
    this.targetInit?.forEach((item) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value == item.value
      })
      if (!obj) return
      this.$set(obj, 'visible', false)
    })
  }

  private addUnit(): void {
    this.selectedUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits.push(Object.assign({}, obj))
      this.$set(obj, 'visible', false)
    })
    this.selectedUnits = []
  }
  private removeUnit(selectedTargetUnits: string[]): void {
    selectedTargetUnits.forEach((unit_value: string) => {
      const obj = this.studyUnits.find((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      if (!obj) return
      this.targetUnits = this.targetUnits.filter((unit: { value: string; text: string }) => {
        return unit.value !== unit_value
      })
      this.$set(obj, 'visible', true)
    })
    this.selectedUnits = []
  }
  private removeUnitByGrade(gradeId: string | number) {
    const unitGradeRemove = this.targetUnits
      .filter((unit: { value: string; text: string; gradeId: number }) => {
        return unit.gradeId == gradeId
      })
      .map((unit) => unit.value)
    this.removeUnit(unitGradeRemove)
  }
  private upUnit(): void {
    // 一番上の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[0].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx - 1)
    })
  }
  private downUnit(): void {
    // 一番下の項目選択時は動作しない
    if (this.selectedTargetUnits.includes(this.targetUnits[this.targetUnits.length - 1].value)) return
    this.selectedTargetUnits.forEach((unit_value: string) => {
      const idx = this.targetUnits.findIndex((unit: { value: string; text: string }) => {
        return unit.value === unit_value
      })
      this.targetUnits = this.reverseTargetUnits(idx, idx + 1)
    })
  }

  private reverseTargetUnits(
    from_idx: number,
    to_idx: number
  ): { value: string; text: string; gradeId: number; pageText: string }[] {
    const result: { value: string; text: string; gradeId: number; pageText: string }[] = []
    this.targetUnits.forEach((unit, index) => {
      if (index === from_idx) result.push(this.targetUnits[to_idx])
      else if (index === to_idx) result.push(this.targetUnits[from_idx])
      else result.push(unit)
    })
    return result
  }

  private getGradeName(gradeId?: string | number): string {
    const grade = this.grades.find((grade) => {
      const id = gradeId || this.gradeId
      return grade.id === id
    })
    if (grade) {
      return grade.name
    }
    return ''
  }

  mounted() {
    this.gradeId = this.grades[0].id
    this.targetUnits = this.targetInit?.map((unit) => {
      const unitReturn: {
        value: string
        text: string
        gradeId: number
        publisherId?: string
      } = {
        value: unit.value,
        text: unit.text + (unit.schoolId || unit.publisherId ? unit.pageText || '' : ''),
        gradeId: unit.gradeId,
      }
      if (this.isScopeBook) {
        unitReturn.publisherId = unit.publisherId
      }
      return unitReturn
    }) as Unit[]
  }
}
