









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PopupScopeBook from '@/components/organisms/v3/mode/PopupScopeBook.vue'
import PopupScopeGdls from '@/components/organisms/v3/mode/PopupScopeGDLS.vue'
import PopupScopeSchool from '@/components/organisms/v3/mode/PopupScopeSchool.vue'
import { Grade, ModeType, Scope, Subject, TypeScope } from '@/types/teacher/mode'

@Component({
  components: {
    ButtonBase,
    PopupScopeGdls,
    PopupScopeSchool,
    PopupScopeBook,
  },
})
export default class SchoolSetting extends Vue {
  private SCOPE_STUDY = [
    { name: 'GDLS単元から設定', value: Scope.GDLS },
    { name: '教科書から設定', value: Scope.BOOKTEXT },
    { name: '学校から設定', value: Scope.SCHOOL },
    //HIDEN-FEATURE { name: '生徒が設定', value: Scope.NON },
  ]

  @Prop()
  private learningRange!: ModeType['learning_range']

  @Prop()
  private onSubmit!: (type: number, data: any, scope: any) => void

  @Prop()
  private subjects!: Subject[]

  private grades!: Grade[]
  private showPopup = false
  private openScope = false

  private isEdit = this.$route.params?.modeId

  private get isChange() {
    return !!Object.keys(this.target).length
  }

  // keep filter in modal
  private hideModal = false
  private scope: any = this.subjects.reduce((result, current) => {
    result[current.id] = TypeScope.Unit
    return result
  }, {})

  @Watch('learningRange', { deep: true })
  setPickScope() {
    this.pickScopeStudy = this.learningRange.type || Scope.GDLS
    this.scope = this.learningRange.scope
  }

  private get target() {
    return this.learningRange.data || {}
  }

  private pickScopeStudy: Scope = Scope.INIT

  private handleSubmit(dataSubmit: any, scope: any) {
    const type = this.pickScopeStudy
    this.scope = cloneDeep(scope)
    const data = cloneDeep(dataSubmit)
    this.onSubmit(type, data, scope)
    this.handleCloseScope()
  }

  private handleCloseScope() {
    this.hideModal = true
  }

  private openModalScope() {
    this.hideModal = false
    this.openScope = true
  }

  private resetData(scope: any) {
    //reset data when switch radio
    this.scope = this.subjects.reduce((result, current) => {
      result[current.id] = TypeScope.Unit
      return result
    }, {})
    scope.value !== this.pickScopeStudy && this.handleSubmit({}, this.scope)
    this.openScope = false
    this.hideModal = false
    this.scope = TypeScope.Unit
  }

  private async getGrades() {
    await Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.grades = res.data.map((grade: { id: number; name: string }) => {
        return {
          id: grade.id,
          name: grade.name,
        }
      })
    })
  }

  private async mounted() {
    this.getGrades()
    this.pickScopeStudy = this.learningRange.type || Scope.GDLS
  }
}
