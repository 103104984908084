




















































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import ModalSelectPage from '@/components/organisms/v3/mode/ModalSelectPage.vue'
import ModalSelectUnit from '@/components/organisms/v3/mode/ModalSelectUnit.vue'
import PopupScopeBase from '@/components/organisms/v3/mode/PopupScopeBase.vue'
import { Page, Subject } from '@/types/teacher/mode'

@Component({
  components: {
    PopupBase,
    ButtonBase,
    SelectBase,
    ModalSelectUnit,
    ModalSelectPage,
  },
})
export default class PopupScopeSchool extends Mixins(PopupScopeBase) {
  private schoolOptionDatas: { text: string; value: number }[] = []
  private hasSchoolOptions = false
  private resetOnChangeSchool() {
    this.subjects.forEach((subject) => {
      this.onReset(+subject.id)
    })
  }
  async getUnits(gradeId: string | number, subject: Subject) {
    const result = await Vue.prototype.$http.httpWithToken.get(
      '/v3/publisher_curriculum_s_units/curriculum_s_units_by_subject',
      {
        params: {
          grade_id: gradeId,
          subject_id: subject.id,
          school_id: this.selectSchool,
        },
      }
    )
    return result.data.units || []
  }

  private selectSchool = Object.keys(this.target)[0] ? this.target[Object.keys(this.target)[0]][0].schoolId : ''

  private selectGrade = Object.keys(this.target)[0]
    ? this.target[Object.keys(this.target)[0]][0].assignGradeId
    : this.grades[0].id

  private get gradeOptionDatas() {
    return this.grades.map((grade) => ({ text: grade.name, value: grade.id }))
  }

  private handleSubmitSchool() {
    this.targetClone = Object.keys(this.targetClone).reduce((accumulator, subjectId) => {
      const newItemWithAssignGradeId = this.targetClone[subjectId].map((item: any) => ({
        ...item,
        assignGradeId: this.selectGrade,
        schoolId: this.selectSchool,
      }))
      accumulator[subjectId] = newItemWithAssignGradeId
      return accumulator
    }, {})

    this.handleSubmit()
  }

  //call list unit
  private async loadSchools() {
    await Vue.prototype.$http.httpWithToken.get(`/schools?branchId=${this.branchId}`).then((res: any) => {
      this.schoolOptionDatas = res.data.schools.map((school: { id: number; name: string }) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
      if (!this.selectSchool) {
        this.selectSchool = this.schoolOptionDatas[0].value
      }
      this.hasSchoolOptions = true
    })
  }

  private schoolId() {
    return +this.selectSchool
  }

  private async checkExistPage(pages: Page[]) {
    const pagesParam = pages.map((item) => ({
      ...item,
      curriculum_mode_code: 'TJ',
      subjectId: this.subject.id,
      schoolId: this.schoolId(),
    }))
    this.callApiCheckPage(pagesParam)
  }

  private mounted() {
    this.loadSchools()
  }
}
