















































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import PopupScopeBase from '@/components/organisms/v3/mode/PopupScopeBase.vue'
import ModalSelectUnit from '@/components/organisms/v3/mode/ModalSelectUnit.vue'
import { Subject } from '@/types/teacher/mode'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PopupBase,
    ButtonBase,
    ModalSelectUnit,
  },
})
export default class PopupScopeGDLS extends Mixins(PopupScopeBase) {

  //call list unit
  async getUnits(gradeId: string | number, subject: Subject) {
    const result = await Vue.prototype.$http.httpWithToken.get('/publisherCurriculums/curriculumSUnitsBySubject', {
      params: {
        gradeId,
        subjectCode: subject.code,
      },
    })
    return result.data.units || []
  }

  openModalSelectUnit(index: number, id: number) {
    this.subjectsClone[index].checked = true
    this.subject = cloneDeep(this.subjectsClone[index])
    this.showModalUnit = true
    this.scrollToSelectUnit(id)
  }

  handleClose() {
    this.$emit('updateStateModalChild', false)
    this.showModalUnit = false
  }

  handleUpdateSelectUnit(data: any) {
    this.targetClone = cloneDeep(data)
  }

  private scrollToSelectUnit(id: number) {
    setTimeout(() => {
      const element = document.getElementById('section' + id)
      element?.scrollIntoView()
    }, 100)
  }
}
