
































import { Component, Vue, Prop } from 'vue-property-decorator'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { BookOptions, Page, Subject } from '@/types/teacher/mode'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PopupBase,
    ButtonBase,
    SelectBase,
  },
})
export default class ModalSelectPage extends Vue {
  @Prop()
  onSubmit!: (pages: Page[]) => void
  @Prop()
  onCancel!: () => void
  @Prop({ default: [{ gradeId: '', startPage: '', endPage: '', publisherId: '' }] })
  pagesProp!: Page[]
  @Prop()
  grades!: { id: number; name: string }[]
  @Prop()
  checkExistPage!: (pages: Page[]) => boolean
  @Prop()
  isScopeBook!: boolean
  @Prop()
  bookOptions!: BookOptions
  @Prop()
  activeSubject!: Subject

  private gradeOptions = this.grades.map((grade) => ({ value: grade.id, text: grade.name }))
  private pages: Page[] = cloneDeep(this.pagesProp)
  private currentInputIndex = 1

  private get errors(): { index: number; error: string }[] {
    const errors = [] as { index: number; error: string }[]
    const regex = /^[0-9]+$/
    for (let i = 0; i < this.pages.length; i++) {
      const row: Page = this.pages[i]
      if (row.startPage !== '' && row.endPage !== '') {
        if (!row.startPage.match(regex) || !row.endPage.match(regex)) {
          errors.push({ index: i, error: 'ページは半角数字で入力してください' })
        } else if (parseInt(row.startPage) < 1) {
          errors.push({ index: i, error: '開始ページは1以上を入力してください' })
        } else if (parseInt(row.startPage) > parseInt(row.endPage)) {
          errors.push({ index: i, error: '終了ページは開始ページより後のページを設定してください' })
        }
      }
    }
    return errors
  }

  private get colortype() {
    const permitted =
      this.pages.length > 0 &&
      !this.pages.filter(
        (row) =>
          row.gradeId === '' || row.startPage === '' || row.endPage === '' || (this.isScopeBook && !row.publisherId)
      ).length &&
      !this.errors.length

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private addRow(): void {
    if (this.currentInputIndex === 5) return
    this.pages.push({ gradeId: '', startPage: '', endPage: '' })
    this.currentInputIndex++
  }

  private removeRow(index: number): void {
    this.pages.splice(index, 1)
    this.currentInputIndex--
  }

  private checkPagesExist() {
    this.checkExistPage(this.pages)
  }
}
