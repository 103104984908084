























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import _ from 'lodash'
import { ClassPolycies, ModeType } from '@/types/teacher/mode'
import { ServiceCodeType } from '@/types/teacher/service'

@Component({
  components: {
    ButtonBase,
    RadioToggle,
    PopupBase,
  },
})
export default class LessonProgressSetting extends Vue {
  @Prop()
  private onSubmit!: (idSelect: ClassPolycies[]) => void

  @Prop()
  private detailMode!: ModeType
  @Prop()
  private dataUpdate!: any

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId
  private showVideoLearningFlow = false
  private isShowModal = false
  private items: ClassPolycies[] = []
  private itemsOrigin: ClassPolycies[] = []
  private isFirst = true
  private get colorType() {
    return this.items.some((item) => item.isEnabled) ? 'blue' : 'pointer-events-none'
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    await this.handleVideoService()
    Vue.prototype.$loading.complete()
  }

  private async handleVideoService() {
    const params = { branchId: this.branchId, academyId: this.academyId }
    const response = await Vue.prototype.$http.httpWithToken.get(`/v3/branches/services`, { params: params })

    const branchService = response.data.branches[0]?.services
    if (branchService) {
      const videoServiceCode = 'gakken'
      const videoService = branchService.find((service: { code: ServiceCodeType }) => service.code === videoServiceCode)
      this.showVideoLearningFlow = videoService.subjects.some(
        (videoServiceOfSubject: { isEnabled: boolean }) => videoServiceOfSubject.isEnabled
      )
    }
  }

  private fillData() {
    const flows = this.detailMode.learning_flows.reduce((result, currentItem) => {
      result[currentItem.classCategoryId || 0] = { isEnabled: currentItem.isEnabled, sortNum: currentItem.sortNum }
      return result
    }, {})
    for (let i = 0; i < this.items.length; i++) {
      const item = flows[this.items[i].classCategoryId || 0]
      this.items[i].isEnabled = item?.isEnabled
      this.items[i].sortNum = item?.sortNum
    }
  }

  @Watch('detailMode', { deep: true })
  watchDetailMode(){
    if (this.detailMode.id) {
      const timeId = setInterval(() => {
        if (this.items.length > 0) {
          this.fillData()
          clearInterval(timeId)
        }
      }, 200)
    }
  }
  @Watch('dataUpdate', { deep: true })
  watchDataUpdate(){
    this.items = (this.items || []).map((item) => {
      const isEnabled = this.dataUpdate.learning_flows.includes(item.classCategoryName)
      return {
        ...item,
        isEnabled: isEnabled,
      }
    })
    this.submitDatas()
  }

  private openPopup() {
    this.itemsOrigin = _.cloneDeep(this.items)
    this.isShowModal = true
  }

  private async loadDatas() {
    // 授業方針情報を取得
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/class_policies`, {
        params: {
          branchId: this.branchId,
          isGroup: true,
        },
      })
      .then((res: any) => {
        const hasId = {}
        const data: ClassPolycies[] = res.data
          .filter((classInfo: ClassPolycies) => {
            const key = classInfo.classCategoryId || 0
            if (!(key in hasId)) {
              hasId[key] = ''
              return true
            }
            return false
          })
          .filter((classInfo: ClassPolycies) => classInfo.classCategoryName !== '確認テスト')
          .map((classInfo: ClassPolycies) => ({ ...classInfo, isEnabled: true }))

        this.items = [...data]
        if (this.isFirst) {
          this.isFirst = false
          this.onSubmit(_.cloneDeep(this.items))
        }
      })
  }
  private submitDatas() {
    this.onSubmit(_.cloneDeep(this.items))
    this.isShowModal = false
  }

  private handleCancel() {
    this.isShowModal = false
    this.items = _.cloneDeep(this.itemsOrigin)
  }

  get ItemChecked() {
    return this.items
      .filter((item) => item.isEnabled && (item.classCategoryName !== '導入動画' || this.showVideoLearningFlow))
      .map((item) => item.classCategoryName)
      .join('→')
  }
}
