






































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import ModalSelectPage from '@/components/organisms/v3/mode/ModalSelectPage.vue'
import ModalSelectUnit from '@/components/organisms/v3/mode/ModalSelectUnit.vue'
import PopupScopeBase from '@/components/organisms/v3/mode/PopupScopeBase.vue'
import { BookOptions, Page, Subject } from '@/types/teacher/mode'

@Component({
  components: {
    PopupBase,
    ButtonBase,
    SelectBase,
    ModalSelectPage,
    ModalSelectUnit,
  },
})
export default class PopupScopeBook extends Mixins(PopupScopeBase) {
  private bookOptions: BookOptions = {}
  private hasBookOptions: { [key: number]: boolean } = {}
  private isShowAll = false

  private resetOnChangeBook(subjectId: number) {
    this.onReset(subjectId)
  }

  private selectBook = this.subjects.reduce((result, subject) => {
    result[subject.subjectId] = this.target[subject.subjectId] ? this.target[subject.subjectId][0].publisherId : ''
    return result
  }, {})

  //call list unit
  async getUnits(gradeId: string | number, subject: Subject, publisherId: string) {
    const result = await Vue.prototype.$http.httpWithToken.get(
      '/v3/publisher_curriculum_s_units/curriculum_s_units_by_subject',
      {
        params: {
          grade_id: gradeId,
          subject_id: subject.id,
          publisher_id: publisherId,
        },
      }
    )
    return result.data.units || []
  }

  private publisherId() {
    return this.selectBook?.[this.subject?.id]
  }

  private async loadBooks() {
    const listIdOfGrade = this.grades.map((grade) => grade.id)
    const loadAllBook = this.subjects.map((subject) =>
      Vue.prototype.$http.httpWithToken.get(`/publisher_curriculums`, {
        params: { branchId: this.branchId, subjectId: subject.id, gradeId: listIdOfGrade },
      })
    )
    Promise.all(loadAllBook).then((results) => {
      results.forEach((result, index) => {
        if (result.data.length > 0) {
          const currentSubjectId = result.config.params.subjectId
          this.hasBookOptions[currentSubjectId] = true
          this.selectBook[currentSubjectId] = this.selectBook[currentSubjectId] || result.data[0].publisherId
          this.bookOptions[currentSubjectId] = result.data.map(
            (book: { publisherId: number; publisherName: string }) => {
              return {
                text: book.publisherName,
                value: book.publisherId,
              }
            }
          )
          // last loop
          if (results.length === index + 1) {
            this.isShowAll = true
          }
        }
      })
    })
  }

  private async checkExistPage(pages: Page[]) {
    const pagesParam = pages.map((item) => ({
      ...item,
      curriculum_mode_code: 'TJ',
      subjectId: this.subject.id,
    }))
    this.callApiCheckPage(pagesParam)
  }

  private mounted() {
    this.loadBooks()
  }
}
