import { render, staticRenderFns } from "./PopupScopeBook.vue?vue&type=template&id=3bf552af&scoped=true&lang=pug&"
import script from "./PopupScopeBook.vue?vue&type=script&lang=ts&"
export * from "./PopupScopeBook.vue?vue&type=script&lang=ts&"
import style0 from "./PopupScopeBook.vue?vue&type=style&index=0&id=3bf552af&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf552af",
  null
  
)

export default component.exports